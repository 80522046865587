import React, { Component } from 'react';
import img from './img/2.webp'
import img2 from './img/Installation.webp'
import img3 from './img/4.webp'
import img4 from './img/5.webp'
import { Container } from 'react-bootstrap';
import "@fontsource/montserrat";

class C2 extends Component {
    render() {
        return (
            <Container className="text-center ">
                <div Class="row m-5">
                    <div class="col-md-12 col-sm-12">
                        <h2>Cum functioneaza disposerul</h2>
                    </div>
                </div>
                <div Class="row ">
                    <div class="col-md-12 col-sm-12 col-lg-5 text-center py-3">
                        <img
                            width="592"
                            height="530"
                            src={img}
                            className="img-fluid"
                            alt="CE"
                        />
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-7 text-center py-3">
                        <p class='mh5'>
                            Prin structura sa, tocatorul este o cameră cu un disc rotativ în interior. Deșeurile alimentare sunt spălate în orificiul chiuvetei, cad pe un disc rotativ și sunt
                            izbite de pereți sub influența forței centrifuge, pe care se află răzătoarele speciale. Sub presiune, deșeurile sunt zdrobite la o dimensiune care îi permite să treacă
                            prin găurile situate de-a lungul marginilor discului. Apoi, împreună cu apa, amestecul rezultat pleacă în canalizare. În tocator nu există lame cuțit, astfel încât
                            dispozitivul este considerat sigur.
                        </p>

                    </div>

                </div>

                <div Class="row m-5">
                    <div class="col-md-12 col-sm-12">
                        <h2>Caracteristici de conexiune</h2>
                    </div>
                </div>


                <div Class="row ">
                    <div class="col-md-12 col-sm-12 col-lg-5 text-center py-3">
                        <img
                            width="400"
                            height="357"
                            src={img3}
                            className="img-fluid"
                            alt="CE"
                        />
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-7 text-center py-3">
                        <p class='mh5'>
                            Înainte de a alege un tocator (și înainte de a începe instalarea), asigurați-vă că există suficient spațiu pentru aceasta: butoiul camerei de lucru a dispozitivului se
                            poate dovedi a fi prea mare, deci nu va fi inutil să verificați toate dimensiunile posibile:
                        </p>
                        <ul class='text-start'>
                            <li>înălțimea și lățimea dispozitivului instalat</li>
                            <li>distanța de la fundul chiuvetei până la linia centrală a prizei</li>
                            <li>distanța de la centrul orificiului de scurgere până la capătul conductei de ramificare</li>
                            <li>distanța de la linia centrului dispozitivului la linia centrului conexiunii dispozitivului</li>
                        </ul>
                    </div>
                    <div Class="row ">
                        <p class='mh5'>
                            De asemenea, trebuie să vă asigurați că nivelul țevii de scurgere este la o înălțime adecvată (adică sub orificiul de evacuare al disposerului).
                        </p>
                        <p class='mh5'>
                            Apropo, coșul de gunoi, care este amplasat în mod tradițional sub chiuvetă, va trebui mutat într-un alt loc după instalarea tocătorului!
                        </p>
                        <p class='mh5'>
                            Pentru a conecta tocatorul la rețea, veți avea nevoie de o priză electrică situată într-un loc în care este exclusă stropire cu apă. De dorit - cu protecție apă.
                        </p>
                        <p class='mh5'>
                            La instalărea tocătorului, de regulă, va trebui să faceți o gaură pentru butonul pneumatic pe chiuvetă sau blat
                        </p>
                    </div>

                    <div Class="row ">
                        <img

                            width="1072"
                            height="351"
                            src={img2}
                            className="img-fluid"
                            alt="CE"
                        />
                    </div>
                </div>

                <div Class="row  afterNavBar">

                    <div class="col-md-12 col-sm-12 col-lg-7 text-center my-3">
                        <p class='mh5'>
                            Tocătoarele pentru resturi alimentare s-au dovedit a fi dispozitive simple și fiabile. De regulă, dacă sunt respectate regulilor de instalare și condițiilor de
                            exploatare, acestea servesc mulți ani și rareori se defectează. Cu toate acestea, chiar și luând în considerare aceste nuanțe, durata reală de viață a tocătoarelor
                            este de cel puțin cinci ani.
                        </p>

                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-5 text-center my-3">
                        <img
                            width="500"
                            height="337"
                            src={img4}
                            className="img-fluid"
                            alt="CE"
                        />
                    </div>

                </div>
            </Container>
        );
    }
}

export default C2;