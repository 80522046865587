import React, { Component } from 'react';
import C1 from '../Components/C1';
import C2 from '../Components/C2';
import Bottom from '../Components/Bottom';
import Top from '../Components/Top';

// import ContactForm from '../Components/ContactForm';

export default class Home extends Component {
    render() {
        return (
            <>
                <Top />
                <C1 />
                <C2 />
               
                <hr class="mlineb" />
                <Bottom />

            </>
        );
    }
}

