import React, { Component } from 'react';
import logo from './img/geohome_logo.webp'

class Top extends Component {
    render() {
        return (
            <div class="Container fixed-top mbg">
                <div class="row text-center mx-3">
                    <div class="col-lg-4 col-4">
                        <a href="/">
                            <img
                                src={logo}
                                height="100"
                                width="100"
                                className="d-inline-block align-center"
                                alt="Logo"
                            />
                        </a>
                    </div>
                    {/* <div class="col-lg-8 mlink d-none d-lg-inline m-auto">
                        <a href="#ochsner">Pompe de Caldura</a>
                        <a href="#meltem">Recuperatoare</a>
                        <a href="#popodusche">Bideu electric</a>
                        <a href="#geohome">Tocatoare</a>
                    </div> */}
                    <div class="col-lg-7 col-7 mlink mt-3 text-center">
                        <h5>(+373) 799 39 333</h5>
                        <h5><a href="mailto:info@geotermal.md">info@geotermal.md</a></h5>
                    </div>
                </div>
                <hr class="mlinet" />
            </div>

        );
    }
}

export default Top;