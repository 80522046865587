import React, { Component } from 'react';
import img from './img/tocator.webp'
import img2 from './img/1.webp'
import { Container } from 'react-bootstrap';
import "@fontsource/montserrat/300.css";

class C1 extends Component {
    render() {
        return (
            <Container className="text-center afterNavBar">
                <div Class="row m-5">
                    <div class="col-md-12 col-sm-12">
                        <h2>Tocator de resturi alimentare</h2>
                    </div>
                </div>
                <div Class="row ">
                    <div class="col-md-12 col-sm-12 col-lg-5 text-center my-3">
                        <img

                            width="356"
                            height="512"
                            src={img}
                            className="img-fluid"
                            alt="CE"
                        />
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-7 text-center my-3">
                        <p class='mh5'>
                            Un disposer (sau tocator resturi alimentare) este un simplu aparat a cărui singură sarcină este de a măcina și utiliza resturile de alimente: coji, oase mici și tot ce mai
                            rămâne după ce am terminat de pregătit mâncarea. Tocatorul se instalează sub chiuvetă (unde suntem obișnuiți să vedem un sifon), datorită căreia resturile de produs
                            zdrobite sunt trimise direct la canalizare.
                        </p>
                        <p class='mh5'>
                            Debarasarea după mese devine mult mai simplă cu un tocător de resturi puternic și silentios. Acum puteţi elimina prin sistemul de evacuare al chiuvetei, în mod sigur şi igienic,
                            resturile de mâncare. Nu mai trebuie să le lăsaţi în coşul de gunoi, unde pot produce mirosuri neplăcute, atrag insecte şi se umplu de bacterii.
                        </p>
                    </div>

                </div>
                <div Class="row flex-column-reverse flex-lg-row ">

                    <div class="col-md-12 col-sm-12 col-lg-6 text-center my-3">
                        <p class='mh5'>
                            Principalele <b> avantaje</b> ale utilizării unui disposer sunt perspectiva de a scăpa de mirosul din coșul de gunoi și necesitatea de a arunca deșeurile organice odată
                            pentru totdeauna. Ca bonusuri suplimentare, sistemul de canalizare nu va fi înfundat cu bucăți mari de alimente și curățări și va trebui să mergeți la aruncat
                            gunoiul mult mai rar.
                        </p>
                        <p class='mh5'>
                            Principalele <b>dezavantaje</b> ale tocatorului de resturi alimentare sunt o ușoară creștere a consumului de apă și electricitate și un nivel ridicat de zgomot în timpul
                            funcționării dispozitivului.

                        </p>
                    </div>
                    <div class="col-md-12 col-sm-12 col-lg-6 text-center my-3">
                        <img

                            width="559"
                            height="531"
                            src={img2}
                            className="img-fluid"
                            alt="CE"
                        />
                    </div>
                </div>
            </Container>

        );
    }
}

export default C1;